.barContainer {
  margin-top: 60px;
  min-width: 5px;
  display: inline-block;
  clear: none;
  background-color: rgb(116, 172, 255);
  margin-right: 2px;
  color: black;
  text-align: center;
  font-weight: bold;
  box-shadow: 5px 2.5px 5px black;
}
#container {
  padding: 20px 20px;
}
// Speed Slider
input[type=range] {
  -webkit-appearance: none;
  background: transparent;
}
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: #efefef;
  height: 18px;
  width: 18px;
  margin-top: -7px;
  border-radius: 99px;
  border: grey solid 1px;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 300px;
  height: 6px;
  border: grey solid 1px;
  background: #ccc;
}
// Cursors

.cursor1 {
  height: 15px;
  line-height: 15px;
  font-size: 9px;
  background-color: black;
  color: white;
  display: none;
}
.cursor2 {
  height: 15px;
  line-height: 15px;
  font-size: 9px;
  background-color: blue;
  color: white;
  display: none;
}
.cursor3 {
  height: 15px;
  line-height: 15px;
  font-size: 9px;
  background-color: red;
  color: white;
  display: none;
}
.barValue {
  height: 15px;
  line-height: 15px;
}

pre {
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-left: 3px solid #f36d33;
  color: #666;
  page-break-inside: avoid;
  font-family: monospace;
  font-size: 12px;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1em 0.5em;
  display: block;
  word-wrap: break-word;
}